import { ITEMS_WIDGET_COMPONENT_IDS } from 'root/components/Items/consts';
import { MENU_WIDGET_COMPONENT_IDS, SECTION_WIDGET_COMPONENT_IDS } from 'root/utils/consts';
import {
  ITEM_ELEMENTS_IDS,
  LABEL_ELEMENTS_IDS,
  SECTION_ELEMENTS_IDS,
  MENU_ELEMENTS_IDS,
  ELEMENT_DATA_BLACK_LIST_MAP,
  PRICE_VARIANTS_ELEMENTS_IDS,
  LABEL_REPEATER_ELEMENTS_IDS,
  MULTIPLE_MENUS_ELEMENTS_IDS,
} from './consts';
import { getRole } from 'root/utils/manifestUtils';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { PRICE_VARIANTS_WIDGET_COMPONENT_IDS } from 'root/components/Varaint/consts';
import type { ElementData } from 'root/types';

const getCompsData = (t: TFunction, isMultiMenuLayoutsExperimentEnabled?: boolean) => {
  const multipleMenusCompsData = isMultiMenuLayoutsExperimentEnabled
    ? {
        [MULTIPLE_MENUS_ELEMENTS_IDS.navigationMultiStateBox]: {
          role: getRole(MENU_WIDGET_COMPONENT_IDS.navigationMultiStateBox),
          label: t('elements-panel.elements.multi-menu-navigation'),
        },
      }
    : {
        [MULTIPLE_MENUS_ELEMENTS_IDS.navigationBar]: {
          role: getRole(MENU_WIDGET_COMPONENT_IDS.navigationBar),
          label: t('elements-panel.elements.multiple.menu-navigation', 'Multiple menus navigation'),
        },
      };

  const menuCompsData = {
    [MENU_ELEMENTS_IDS.menuTitle]: {
      role: getRole(MENU_WIDGET_COMPONENT_IDS.menuTitle),
      label: t('elements-panel.elements.menu-title', 'Menu name'),
    },
    [MENU_ELEMENTS_IDS.menuImage]: {
      role: getRole(MENU_WIDGET_COMPONENT_IDS.sbsImage),
      label: t('elements-panel.elements.item-image', 'Image'), // TODO: after localitzion update to 'elements-panel.elements.menu-image'
    },
    [MENU_ELEMENTS_IDS.menuDescription]: {
      role: getRole(MENU_WIDGET_COMPONENT_IDS.menuDescription),
      label: t('elements-panel.elements.menu-description', 'Menu description'),
    },
    // TODO: remove when isNewCategoriesAndContentElementsPanel is merged
    [MENU_ELEMENTS_IDS.horizontalMenu]: {
      role: getRole(MENU_WIDGET_COMPONENT_IDS.navigationBar),
      label: t('elements-panel.elements.menu-horizontal-menu', 'Horizontal Menu'),
    },
  };

  const sectionCompsData = {
    [SECTION_ELEMENTS_IDS.sectionTitle]: {
      role: getRole(SECTION_WIDGET_COMPONENT_IDS.sectionTitle),
      label: t('elements-panel.elements.section-title', 'Section Title'),
    },
    [SECTION_ELEMENTS_IDS.sectionDescription]: {
      role: getRole(SECTION_WIDGET_COMPONENT_IDS.sectionDescription),
      label: t('elements-panel.elements.section-description', 'Section description'),
    },
    // TODO: remove when isNewCategoriesAndContentElementsPanel is merged
    [SECTION_ELEMENTS_IDS.sectionDivider]: {
      role: getRole(SECTION_WIDGET_COMPONENT_IDS.sectionDivider),
      label: t('elements-panel.elements.section-divider', 'Section divider'),
    },
  };

  const itemCompsData = {
    [ITEM_ELEMENTS_IDS.itemDescription]: {
      role: getRole(ITEMS_WIDGET_COMPONENT_IDS.itemDescription),
      label: t('elements-panel.elements.item-description', 'Description'),
    },
    [ITEM_ELEMENTS_IDS.itemPrice]: {
      role: getRole(ITEMS_WIDGET_COMPONENT_IDS.itemPrice),
      label: t('elements-panel.elements.item-price', 'Price'),
    },
    [ITEM_ELEMENTS_IDS.itemCurrency]: {
      role: getRole(ITEMS_WIDGET_COMPONENT_IDS.itemCurrency),
      label: t('elements-panel.elements.item-currency', 'Currency'),
    },
    // TODO: remove when isNewCategoriesAndContentElementsPanel is merged
    [ITEM_ELEMENTS_IDS.itemDivider]: {
      role: getRole(ITEMS_WIDGET_COMPONENT_IDS.itemDivider),
      label: t('elements-panel.elements.item-divider', 'Divider'),
    },
    [ITEM_ELEMENTS_IDS.itemImage]: {
      role: getRole(ITEMS_WIDGET_COMPONENT_IDS.itemImage),
      label: t('elements-panel.elements.item-image', 'Image'),
    },
  };

  const labelsCompsData = {
    [LABEL_ELEMENTS_IDS.text]: {
      role: LABEL_ELEMENTS_IDS.text,
      label: t('elements-panel.elements.label-text', 'Text'),
    },
    [LABEL_ELEMENTS_IDS.icon]: {
      role: LABEL_ELEMENTS_IDS.icon,
      label: t('elements-panel.elements.label-icon', 'Icon'),
    },
    [LABEL_ELEMENTS_IDS.label]: {
      role: LABEL_ELEMENTS_IDS.label,
      label: t('elements-panel.elements.item-label'),
    },
  };

  const labelsRepeaterCompsData = {
    [LABEL_REPEATER_ELEMENTS_IDS.label]: {
      role: LABEL_REPEATER_ELEMENTS_IDS.label,
      label: t('elements-panel.elements.item-label'),
    },
  };

  const priceVariantsCompsData = {
    [PRICE_VARIANTS_ELEMENTS_IDS.variantName]: {
      role: getRole(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantName),
      label: t('elements-panel.elements.variant-name'),
    },
    [PRICE_VARIANTS_ELEMENTS_IDS.variantPrice]: {
      role: getRole(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice),
      label: t('elements-panel.elements.variant-price'),
    },
    [PRICE_VARIANTS_ELEMENTS_IDS.variantCurrency]: {
      role: getRole(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantCurrency),
      label: t('elements-panel.elements.variant-currency'),
    },
    // TODO: remove when isNewCategoriesAndContentElementsPanel is merged
    [PRICE_VARIANTS_ELEMENTS_IDS.variantDivider]: {
      role: getRole(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantDivider),
      label: t('elements-panel.elements.variant-divider'),
    },
  };

  const dividersCompsData = {
    [SECTION_ELEMENTS_IDS.sectionDivider]: {
      role: getRole(SECTION_WIDGET_COMPONENT_IDS.sectionDivider),
      label: t('elements-panel.elements.divider-category.section-divider', 'Sections'),
    },
    [ITEM_ELEMENTS_IDS.itemDivider]: {
      role: getRole(ITEMS_WIDGET_COMPONENT_IDS.itemDivider),
      label: t('elements-panel.elements.divider-category.item-divider', 'Items'),
    },
    [PRICE_VARIANTS_ELEMENTS_IDS.variantDivider]: {
      role: getRole(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantDivider),
      label: t('elements-panel.elements.divider-category.variant-divider', 'Variants'),
    },
  };

  return {
    multipleMenusCompsData,
    menuCompsData,
    sectionCompsData,
    itemCompsData,
    labelsCompsData,
    labelsRepeaterCompsData,
    priceVariantsCompsData,
    dividersCompsData,
  };
};

const getCategoriesIds = (t: TFunction) => ({
  multipleMenus: t('elements-panel.categories.multiple-menus-header', 'Multiple menus'),
  menuHeader: t('elements-panel.categories.menu-header', 'Menu Header'),
  sectionHeader: t('elements-panel.categories.section-header', 'Section Header'),
  item: t('elements-panel.categories.item', 'Menu items'),
  labels: t('elements-panel.categories.labels', 'Labels'),
  priceVariants: t('elements-panel.categories.variants', 'Variants'),
  dividers: t('elements-panel.categories.dividers-header', 'Dividers'),
});

const getElementsPanelData = (
  t: TFunction,
  opts: {
    preset?: keyof typeof ELEMENT_DATA_BLACK_LIST_MAP;
    isMenuElements: boolean;
    withBadgeElement?: boolean;
    withNewCategories?: boolean;
    withOneLabelElement?: boolean;
    isMultiMenuLayoutsExperimentEnabled?: boolean;
    withLabelsTooltip?: boolean;
  }
) => {
  const CATEGORIES_IDS = getCategoriesIds(t);
  const categories = {
    multipleMenus: {
      id: CATEGORIES_IDS.multipleMenus,
      title: CATEGORIES_IDS.multipleMenus,
    },
    menu: {
      id: CATEGORIES_IDS.menuHeader,
      title: CATEGORIES_IDS.menuHeader,
    },
    section: {
      id: CATEGORIES_IDS.sectionHeader,
      title: CATEGORIES_IDS.sectionHeader,
    },
    item: {
      id: CATEGORIES_IDS.item,
      title: CATEGORIES_IDS.item,
    },
    labels: {
      id: CATEGORIES_IDS.labels,
      title: CATEGORIES_IDS.labels,
    },
    priceVariants: {
      id: CATEGORIES_IDS.priceVariants,
      title: CATEGORIES_IDS.priceVariants,
    },
    dividers: {
      id: CATEGORIES_IDS.dividers,
      title: CATEGORIES_IDS.dividers,
    },
  };

  const categoriesData = [
    ...(opts.withNewCategories ? [categories.multipleMenus] : []),
    ...(opts.isMenuElements ? [categories.menu] : []),
    categories.section,
    categories.item,
    categories.priceVariants,
    categories.labels,
    ...(opts.withNewCategories ? [categories.dividers] : []),
  ];

  const {
    multipleMenusElementData,
    menuElementData,
    sectionElementData,
    itemsWithoutLabelElementsData,
    itemsWithLabelElementsData,
    itemsWithBadgeElementsData,
    labelsElementsData,
    priceVariantsElementsData,
    dividerElementsData,
  } = getElementsData(
    t,
    opts.preset,
    opts.withNewCategories,
    opts.isMultiMenuLayoutsExperimentEnabled,
    opts.withLabelsTooltip
  );

  let itemsElementsData;
  if (opts.withBadgeElement) {
    itemsElementsData = itemsWithBadgeElementsData;
  } else if (opts.withOneLabelElement) {
    itemsElementsData = itemsWithLabelElementsData;
  } else {
    itemsElementsData = itemsWithoutLabelElementsData;
  }

  const elementsData = [
    ...multipleMenusElementData,
    ...(opts.isMenuElements ? menuElementData : []),
    ...sectionElementData,
    ...itemsElementsData,
    ...priceVariantsElementsData,
    ...(!opts.withOneLabelElement && !opts.withBadgeElement ? labelsElementsData : []),
    ...dividerElementsData,
  ];

  return {
    categoriesData,
    elementsData,
  };
};

const getElementsData = (
  t: TFunction,
  preset?: keyof typeof ELEMENT_DATA_BLACK_LIST_MAP,
  withNewCategories?: boolean,
  isMultiMenuLayoutsExperimentEnabled?: boolean,
  withLabelsTooltip?: boolean
) => {
  const CATEGORIES_IDS = getCategoriesIds(t);
  const {
    multipleMenusCompsData,
    menuCompsData,
    sectionCompsData,
    itemCompsData,
    labelsCompsData,
    labelsRepeaterCompsData,
    priceVariantsCompsData,
    dividersCompsData,
  } = getCompsData(t, isMultiMenuLayoutsExperimentEnabled);

  const multipleMenusElementData = isMultiMenuLayoutsExperimentEnabled
    ? [
        {
          elementId: MULTIPLE_MENUS_ELEMENTS_IDS.navigationMultiStateBox,
          label: multipleMenusCompsData.navigation.label,
          identifier: { role: multipleMenusCompsData.navigation.role },
          categoryId: CATEGORIES_IDS.multipleMenus,
        },
      ]
    : [
        {
          elementId: MULTIPLE_MENUS_ELEMENTS_IDS.navigationBar,
          label: multipleMenusCompsData.navigationBar.label,
          identifier: { role: multipleMenusCompsData.navigationBar.role },
          categoryId: CATEGORIES_IDS.multipleMenus,
        },
      ];

  const menuElementData = [
    {
      elementId: MENU_ELEMENTS_IDS.menuTitle,
      label: menuCompsData.menuTitle.label,
      identifier: { role: menuCompsData.menuTitle.role },
      categoryId: CATEGORIES_IDS.menuHeader,
      index: 0,
    },
    {
      elementId: MENU_ELEMENTS_IDS.menuDescription,
      label: menuCompsData.menuDescription.label,
      identifier: { role: menuCompsData.menuDescription.role },
      categoryId: CATEGORIES_IDS.menuHeader,
      index: 1,
    },
    {
      elementId: MENU_ELEMENTS_IDS.menuImage,
      label: menuCompsData.menuImage.label,
      identifier: { role: menuCompsData.menuImage.role },
      categoryId: CATEGORIES_IDS.menuHeader,
      index: 2,
    },
    // TODO: remove when isNewCategoriesAndContentElementsPanel is merged
    {
      elementId: MENU_ELEMENTS_IDS.horizontalMenu,
      label: menuCompsData.horizontalMenu.label,
      identifier: { role: menuCompsData.horizontalMenu.role },
      categoryId: CATEGORIES_IDS.menuHeader,
      index: 3,
    },
  ];

  const sectionElementData = [
    {
      elementId: SECTION_ELEMENTS_IDS.sectionTitle,
      label: sectionCompsData.sectionTitle.label,
      identifier: { role: sectionCompsData.sectionTitle.role },
      categoryId: CATEGORIES_IDS.sectionHeader,
    },
    {
      elementId: SECTION_ELEMENTS_IDS.sectionDescription,
      label: sectionCompsData.sectionDescription.label,
      identifier: { role: sectionCompsData.sectionDescription.role },
      categoryId: CATEGORIES_IDS.sectionHeader,
    },
    // TODO: remove when isNewCategoriesAndContentElementsPanel is merged
    {
      elementId: SECTION_ELEMENTS_IDS.sectionDivider,
      label: sectionCompsData.sectionDivider.label,
      identifier: { role: sectionCompsData.sectionDivider.role },
      categoryId: CATEGORIES_IDS.sectionHeader,
    },
  ];

  const itemsWithoutLabelElementsData = [
    {
      elementId: ITEM_ELEMENTS_IDS.itemDescription,
      label: itemCompsData.itemDescription.label,
      identifier: { role: itemCompsData.itemDescription.role },
      categoryId: CATEGORIES_IDS.item,
    },
    {
      elementId: ITEM_ELEMENTS_IDS.itemPrice,
      label: itemCompsData.itemPrice.label,
      identifier: { role: itemCompsData.itemPrice.role },
      categoryId: CATEGORIES_IDS.item,
    },
    {
      elementId: ITEM_ELEMENTS_IDS.itemCurrency,
      label: itemCompsData.itemCurrency.label,
      identifier: { role: itemCompsData.itemCurrency.role },
      categoryId: CATEGORIES_IDS.item,
    },
    // TODO: remove when isNewCategoriesAndContentElementsPanel is merged
    {
      elementId: ITEM_ELEMENTS_IDS.itemDivider,
      label: itemCompsData.itemDivider.label,
      identifier: { role: itemCompsData.itemDivider.role },
      categoryId: CATEGORIES_IDS.item,
    },
    {
      elementId: ITEM_ELEMENTS_IDS.itemImage,
      label: itemCompsData.itemImage.label,
      identifier: { role: itemCompsData.itemImage.role },
      categoryId: CATEGORIES_IDS.item,
    },
  ];

  const labelsElementsData = [
    {
      label: labelsCompsData[LABEL_ELEMENTS_IDS.text].label,
      identifier: { role: labelsCompsData[LABEL_ELEMENTS_IDS.text].role },
      categoryId: CATEGORIES_IDS.labels,
      index: 0,
    },
    {
      label: labelsCompsData[LABEL_ELEMENTS_IDS.icon].label,
      identifier: { role: labelsCompsData[LABEL_ELEMENTS_IDS.icon].role },
      categoryId: CATEGORIES_IDS.labels,
      index: 1,
    },
  ];

  const labelContainerElementData = [
    {
      elementId: LABEL_ELEMENTS_IDS.label,
      label: labelsCompsData[LABEL_ELEMENTS_IDS.label].label,
      identifier: { role: labelsCompsData[LABEL_ELEMENTS_IDS.label].role },
      categoryId: CATEGORIES_IDS.item,
    },
  ];

  const labelContainerInRepeaterElementData = [
    {
      elementId: LABEL_REPEATER_ELEMENTS_IDS.label,
      label: labelsRepeaterCompsData[LABEL_REPEATER_ELEMENTS_IDS.label].label,
      identifier: { role: labelsRepeaterCompsData[LABEL_REPEATER_ELEMENTS_IDS.label].role },
      categoryId: CATEGORIES_IDS.item,
      ...(withLabelsTooltip && {
        tooltipData: {
          content: t('elements-panel.elements.item-label.tooltip'),
        },
      }),
    },
  ];

  const itemsWithBadgeElementsData = itemsWithoutLabelElementsData
    .slice(0, 3)
    .concat(...labelContainerInRepeaterElementData, itemsWithoutLabelElementsData.slice(3));

  const itemsWithLabelElementsData = itemsWithoutLabelElementsData
    .slice(0, 3)
    .concat(...labelContainerElementData, itemsWithoutLabelElementsData.slice(3));

  const priceVariantsElementsData = [
    {
      elementId: PRICE_VARIANTS_ELEMENTS_IDS.variantName,
      label: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantName].label,
      identifier: { role: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantName].role },
      categoryId: CATEGORIES_IDS.priceVariants,
      index: 0,
    },
    {
      elementId: PRICE_VARIANTS_ELEMENTS_IDS.variantPrice,
      label: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantPrice].label,
      identifier: { role: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantPrice].role },
      categoryId: CATEGORIES_IDS.priceVariants,
      index: 1,
    },
    {
      elementId: PRICE_VARIANTS_ELEMENTS_IDS.variantCurrency,
      label: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantCurrency].label,
      identifier: { role: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantCurrency].role },
      categoryId: CATEGORIES_IDS.priceVariants,
      index: 1,
    },
    // TODO: remove when isNewCategoriesAndContentElementsPanel is merged
    {
      elementId: PRICE_VARIANTS_ELEMENTS_IDS.variantDivider,
      label: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantDivider].label,
      identifier: { role: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantDivider].role },
      categoryId: CATEGORIES_IDS.priceVariants,
      index: 1,
    },
  ];

  const dividerElementsData = [
    {
      elementId: SECTION_ELEMENTS_IDS.sectionDivider,
      label: dividersCompsData.sectionDivider.label,
      identifier: { role: dividersCompsData.sectionDivider.role },
      categoryId: CATEGORIES_IDS.dividers,
    },
    {
      elementId: ITEM_ELEMENTS_IDS.itemDivider,
      label: dividersCompsData.itemDivider.label,
      identifier: { role: dividersCompsData.itemDivider.role },
      categoryId: CATEGORIES_IDS.dividers,
    },
    {
      elementId: PRICE_VARIANTS_ELEMENTS_IDS.variantDivider,
      label: dividersCompsData.variantDivider.label,
      identifier: { role: priceVariantsCompsData[PRICE_VARIANTS_ELEMENTS_IDS.variantDivider].role },
      categoryId: CATEGORIES_IDS.dividers,
    },
  ];

  const elementsToFilter = (preset && ELEMENT_DATA_BLACK_LIST_MAP[preset]) || [];

  const getFilteredDataByPreset = (elementsGroup: ElementData[], elementToFilter?: string) => {
    if (withNewCategories && elementToFilter) {
      elementsGroup = elementsGroup.filter(({ elementId }) => elementId === undefined || elementId !== elementToFilter);
    }
    return elementsGroup
      .filter(({ elementId }) => elementId === undefined || !elementsToFilter.includes(elementId))
      .map(({ elementId, ...elementData }, index) => ({ ...elementData, index }));
  };

  return {
    multipleMenusElementData,
    menuElementData: getFilteredDataByPreset(menuElementData, MENU_ELEMENTS_IDS.horizontalMenu),
    sectionElementData: getFilteredDataByPreset(sectionElementData, SECTION_ELEMENTS_IDS.sectionDivider),
    itemsWithoutLabelElementsData: getFilteredDataByPreset(
      itemsWithoutLabelElementsData,
      ITEM_ELEMENTS_IDS.itemDivider
    ),
    itemsWithLabelElementsData: getFilteredDataByPreset(itemsWithLabelElementsData, ITEM_ELEMENTS_IDS.itemDivider),
    itemsWithBadgeElementsData: getFilteredDataByPreset(itemsWithBadgeElementsData, ITEM_ELEMENTS_IDS.itemDivider),
    labelsElementsData,
    priceVariantsElementsData: getFilteredDataByPreset(
      priceVariantsElementsData,
      PRICE_VARIANTS_ELEMENTS_IDS.variantDivider
    ),
    dividerElementsData: getFilteredDataByPreset(dividerElementsData),
  };
};

export const getMenuElementsPanelData = (
  t: TFunction,
  preset?: keyof typeof ELEMENT_DATA_BLACK_LIST_MAP,
  isLabelLayouterWidgetExperimentEnabled?: boolean,
  isNewCategoriesElementsPanelExperimentEnabled?: boolean,
  isReplaceLabelElementsExperimentEnabled?: boolean,
  isMultiMenuLayoutsExperimentEnabled?: boolean,
  isLabelsTooltipExperimentEnabled?: boolean
) => {
  return getElementsPanelData(t, {
    preset,
    isMenuElements: true,
    withBadgeElement: isLabelLayouterWidgetExperimentEnabled,
    withNewCategories: isNewCategoriesElementsPanelExperimentEnabled,
    withOneLabelElement: isReplaceLabelElementsExperimentEnabled,
    isMultiMenuLayoutsExperimentEnabled,
    withLabelsTooltip: isLabelsTooltipExperimentEnabled,
  });
};
