import type { ComponentRef, EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { ITEMS_PAGE_BM_URL, MENUS_SHOWCASE_APP_DEF_ID, MENUS_SHOWCASE_BM_URL } from './consts';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import { MenusClient } from 'root/api/MenusClient';
import type { MenusOrder } from 'root/components/Menu/types';
import { getWidgetProps } from './commonUtils';
import { DashboardPage } from 'root/types';

export const openManageMenuDashboardPanel = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  dashboardPage: DashboardPage,
  referralInfo: string,
  componentRef?: ComponentRef
) => {
  let url: string;

  switch (dashboardPage) {
    case DashboardPage.menus:
      const { data: menusFromServer } = await getMonitoredApiCall(
        () => MenusClient(flowAPI.essentials.httpClient).getAll({}),
        flowAPI.reportError
      );
      const widgetProps = componentRef && (await getWidgetProps(editorSDK, componentRef));
      const menusOrder = widgetProps?.menusOrder as MenusOrder;
      const menuId = menusOrder?.[0] || menusFromServer?.data?.[0]?.id;
      url = menuId ? `${MENUS_SHOWCASE_BM_URL}/${menuId}` : MENUS_SHOWCASE_BM_URL;
      break;
    case DashboardPage.items:
      url = ITEMS_PAGE_BM_URL;
      break;
  }

  url = `${url}?referralInfo=${referralInfo}`;

  editorSDK.editor
    .openDashboardPanel(MENUS_SHOWCASE_APP_DEF_ID, {
      url,
      closeOtherPanels: false,
    })
    .then(async () => {
      await editorSDK.document.application.livePreview.refresh('', {
        shouldFetchData: true,
        source: '',
      });
      await editorSDK.document.application.reloadManifest();
    });
};
